<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/cp-logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="screen-title">
              <h2>
                {{ $t('page_reset_password_title') }}
              </h2>
            </div>
            <div class="screen-form">
              <form>
                <v-text-field
                  :placeholder="$t('page_reset_password_email_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="email"
                  :name="$uuid.v4()"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-text-field
                  :placeholder="$t('page_reset_password_password_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="new_password"
                  :name="$uuid.v4()"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-text-field
                  :placeholder="
                    $t('page_reset_password_password_confirm_placeholder')
                  "
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="confirm_password"
                  :name="$uuid.v4()"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-btn
                  block
                  class="text-capitalize btn-submit"
                  large
                  color="primary"
                  :disabled="!isValid"
                  @click="submit"
                  >{{ $t('submit') }}</v-btn
                >
                <div class="marin-bottom-field"></div>
                <div class="forget-blk">
                  <span class="forgot-text">
                    <router-link :to="{ name: 'Login' }">
                      {{ $t('page_reset_password_login_redirect_text') }}
                    </router-link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>© 2017 - 2021 redish Co., Ltd.</p>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      showPassword: false,
      new_password: null,
      confirm_password: null,
      isValid: true,
      rules: {
        new_password: [
          v =>
            !!v ||
            this.$t('page_reset_password_password_required_validation_text'),
          v =>
            (v && v.length >= 6) ||
            this.t('page_reset_password_password_min_validation_text')
        ],
        confirm_password: [
          v =>
            !!v ||
            this.$t(
              'page_reset_password_password_new_required_validation_text'
            ),
          v =>
            (v && v.length >= 6) ||
            this.$t('page_reset_password_password_min_validation_text'),
          v =>
            v === this.new_password ||
            this.$t('page_reset_password_password_matches_validation_text')
        ]
      }
    }
  },

  methods: {
    submit() {
      let email = this.email
      let token = this.$route.params.token
      let password_confirmation = this.confirm_password
      let password = this.new_password
      this.$store
        .dispatch('AUTH_RESET', {
          email,
          password_confirmation,
          password,
          token
        })
        .then(
          response => {
            console.log(response)
            if (response.status >= 200 && response.status <= 299) {
              this.$store.dispatch('ALERT', {
                show: true,
                text: this.$t('page_reset_password_success_text')
              })
              this.email = ''
              this.new_password = ''
              this.confirm_password = ''
            } else {
              this.$store.dispatch('ALERT', {
                show: true,
                text: this.$t('page_reset_password_error')
              })
            }
          },
          error => {
            console.error(error)
            this.$store.dispatch('ALERT', {
              show: true,
              text: this.$t('page_reset_password_error')
            })
          }
        )
        .catch(err => console.log(err))
    },
    login() {}
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  font-size: 10px;
}
body {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}
input,
button,
select,
textarea {
  -webkit-appearance: none;
}
/*=====================Reset Css End==================================*/

/*=========================== Global Start here ===============================*/
a {
  text-decoration: none;
}
input::-webkit-input-placeholder {
  color: #b8b8b8;
}
input::-moz-placeholder {
  color: #b8b8b8;
}
input:-ms-input-placeholder {
  color: #b8b8b8;
}
input:-moz-placeholder {
  color: #b8b8b8;
}

.custom-cr input {
  display: none;
}
.custom-cr label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 3.6rem;
  font-size: 1.2rem;
  color: #686868;
}
.custom-cr label:before {
  content: '';
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  left: 0;
  bottom: 1px;
  background-color: #f9f9f9;
  border: 0.5px solid #d0d0d0;
  border-radius: 0.2rem;
}
.custom-cr input[type='checkbox']:checked + label:before {
  content: '\2713';
  font-size: 1.5rem;
  color: #20368b;
  text-align: center;
  line-height: 1.6rem;
  font-weight: 600;
}

/*====Login Screen CSS======*/
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.login-section {
  position: relative;
  flex: 1 0 auto;
}
.login-blk {
  height: 100%;
  display: flex;
  align-items: center;
}
.screen-lft {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.screen-lft:before {
  /*background-image: url('~@/assets/images/screen-bg.png');:style="{ backgroundImage: `url(${backgroundUrl})` }" */
  background-image: url('~@/assets/images/screen-bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  /*z-index: -1;*/
}
.logo {
  padding: 0 14rem 0 0;
}
.logo a {
  display: block;
}
.screen-rgt {
  width: 50%;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.screen-outer {
  padding: 0 0 0 6rem;
  padding-bottom: 30px !important;
}
.screen-title {
  text-align: center;
  margin: 0 0 3rem;
}
.screen-title h2 {
  font-weight: 500;
  font-size: 2rem;
  color: #454545;
}
.screen-form {
  min-width: 31.7rem;
}
.form-blk {
  margin: 0 0 2.6rem;
}
.form-blk:nth-child(2) {
  margin: 0 0 3.1rem;
}
.form-blk input {
  background: #f0f0f0;
  border: 0.5px solid #d0d0d0;
  border-radius: 2rem;
  width: 100%;
  font-size: 1.2rem;
  padding: 1.1rem 2rem;
  color: #000;
}
.remember-blk {
  margin: 0 0 3.1rem;
}
.login-btn {
  margin: 0 0 3rem;
}
.login-btn .login {
  background: #20368b;
  border-radius: 2rem;
  color: #fff;
  width: 100%;
  padding: 1.1rem 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  border: none;
}
.forget-blk {
  text-align: center;
}
.forget-blk a {
  font-size: 1.2rem;
  color: #999999;
}

.copyright-blk {
  background: #434a66;
  padding: 0 1rem;
  text-align: right;
  flex-shrink: 0;
}
.copyright-blk p {
  font-size: 1.4rem;
  color: #ffffff;
  padding: 1rem;
}

/*=====Responsive CSS=====*/
@media all and (min-width: 1800px) {
}
@media all and (max-width: 1024px) {
  .screen-lft {
    justify-content: center;
  }
  .logo {
    padding: 0;
  }
  .screen-rgt {
    align-items: center;
  }
  .screen-outer {
    padding: 0;
  }
}
@media all and (max-width: 960px) {
}
@media screen and (max-width: 767px) {
  .login-section:before {
    background-image: url('~@/assets/images/screen-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .login-blk {
    padding: 8rem 1.5rem;
    height: auto;
    flex-direction: column;
  }
  .screen-lft {
    width: 100%;
    margin: 0 0 2rem;
  }
  .screen-lft::before {
    display: none;
  }
  .logo a img {
    margin: 0 auto;
  }
  .screen-rgt {
    width: 100%;
  }
  .screen-outer {
    width: 100%;
  }
  .screen-title {
    margin: 0 0 2rem;
  }
  .screen-form {
    min-width: auto;
  }
  .form-blk {
    margin: 0 0 1.6rem;
  }
  .form-blk:nth-child(2) {
    margin: 0 0 2rem;
  }
  .remember-blk {
    margin: 0 0 2rem;
  }
}
.marin-bottom-field {
  height: 2.6rem;
}
.input-text {
  height: 37px;
  padding-top: 10px;
  background: #f0f0f0;
  border: 0.5px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #b8b8b8;
}
div .v-input__control {
  height: 37px;
}
input {
  max-height: 37px !important;
}
.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot,
.v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot,
.v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot {
  min-height: 37px;
}

button .btn-submit {
  background-color: #20368b !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}
.v-btn__content {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
.v-btn {
  border-radius: 20px !important;
  height: 37px !important;
}
/*.v-application .primary {
    background-color: #20368B !important;
    border-color: #20368B !important;
}*/
.v-application p {
  margin-bottom: 0px;
}
.v-btn:not(.v-btn--round).v-size--large {
  background-color: #20368b !important;
  border-color: #20368b !important;
}

.forgot-text {
  color: #999999 !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
}
.forgot-text a {
  color: #999999 !important;
}
.chkbox-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #686868;
}
v-text-field--outlined .v-text-field__slot,
.v-text-field--single-line .v-text-field__slot {
  align-items: inherit;
  height: 37px;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 37px;
}

.v-image__image {
  height: 123vh !important;
}
.txt-class {
  opacity: 1 !important;
}
.button {
  background-color: #20368b !important;
}
.break {
  height: 20px;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: none !important;
}
v-text-field--outlined .v-text-field__slot,
.v-text-field--single-line .v-text-field__slot {
  height: 35px !important;
}
</style>
